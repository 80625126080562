import { defineStore } from "pinia"

export const state = () => ({
  isOverseasPage: false,
})

export type RootState = ReturnType<typeof state>

export const getters = {
  isOverseasPage: (state: RootState) => state.isOverseasPage,
}

export const useOverseasStore = defineStore({
  id: "overseas",
  state,
  actions: {
    setOverseasPage(isOverseasPage: boolean): void {
      this.isOverseasPage = isOverseasPage
    },
  },
})
